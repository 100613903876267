import React from "react";
import { Link } from "react-router-dom";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
import Header from "../components/Header";
import logo from ".././assets/img/logo/logo.png";
import logo1 from ".././assets/img/logo/logo-black.png";
import Footer from "../components/Footer";
export default function dl() {
  return (
    <div>
      <Header phoneNumber="  (877) 382 2797" />

      <div className="aboslute_top">
        <img src={logo} alt="" />
      </div>
      <div className="absolute_bottom">
        <div className="tp-slide-btn-box">
          <a className="tp-btn-3" href="tel:+18773822797">
            Call Now: (877) 382 2797
          </a>
        </div>
      </div>
      <h1 className="Page_Heading">DL</h1>
      <Footer phoneNumber=" (877) 382 2797" />
    </div>
  );
}
