import React from "react";
import { Link } from "react-router-dom";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
import Header from "../components/Header";
import logo from ".././assets/img/logo/logo.png";
import logo1 from ".././assets/img/logo/logo-black.png";
import Footer from "../components/Footer";
import phone from "../assets/img/phone-call.png";
import email from "../assets/img/mail.png";
// Import your images if needed

export default function Contact() {
  return (
    <div>
      <Header phoneNumber="(888) 525-2918" />

      <div className="aboslute_top">
        <img src={logo} alt="" />
      </div>
      <div className="absolute_bottom">
        <div className="tp-slide-btn-box">
          <a className="tp-btn-3" href="tel:+18885252918">
            Call Now: (888) 525-2918
          </a>
        </div>
      </div>
      <div className="disclaimer-content cntct">
        <div className="tp-section-subtitle hd">Contact</div>
        <ul>
          <li className="li">
            <img src={phone} alt="" />
            <a href="tel:+18885252918">(888) 525-2918</a>
          </li>
          <li>
            <img src={email} alt="" />
            <a href="mailto:info@usavelifeinsurance.com">
              info@usavelifeinsurance.com
            </a>
          </li>
        </ul>
      </div>
      <Footer phoneNumber="(888) 525-2918" />
    </div>
  );
}
