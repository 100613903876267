import React from "react";
import "./assets/css/bootstrap.css";
import "./assets/css/meanmenu.css";
import "./assets/css/animate.css";
import "./assets/css/swiper-bundle.css";
import "./assets/css/slick.css";
import "./assets/css/nouislider.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/font-awesome-pro.css";
import "./assets/css/flaticon.css";
import "./assets/css/spacing.css";
import "./assets/css/main.css";
import img4 from "./assets/img/about/tp-about-3.3.jpg";
import img7 from "./assets/img/features/img-bg.jpg";
import img8 from "./assets/img/features/img-bg-thrd.jpg";
import img9 from "./assets/img/features/img.jpg";
import img12 from "./assets/img/cta/cta3-img-right.png";
import img13 from "./assets/img/cta/shap2.png";
import img14 from "./assets/img/cta/shap3.png";
import logo from "./assets/img/logo/logo.png";
import icon22 from "./assets/img/simple logo/simple logo/1.png";
import icon5 from "./assets/img/simple logo/simple logo/5.png";
import icon7 from "./assets/img/simple logo/simple logo/7.png";
import icon8 from "./assets/img/simple logo/simple logo/fund.png";
import icon9 from "./assets/img/simple logo/simple logo/9.png";
import icon11 from "./assets/img/simple logo/simple logo/11.png";
import icon13 from "./assets/img/simple logo/simple logo/13.png";
import icon14 from "./assets/img/simple logo/simple logo/14.png";
// Import your images if needed
import bgImage1 from "./assets/img/h1.jpeg";
import bgImage2 from "./assets/img/h2.jpeg";
import bgImage3 from "./assets/img/h3.jpeg";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Slider from "./components/Slider";
import Slider2 from "./components/Slider2";
// Install Swiper modules
export default function landingPage() {
  const slides = [
    {
      heading:
        "Protect Your Future <br /> With Affordable <br /> Term Life Insurance",
      text: "",
      phoneNumber: "(888) 525-2918",
      image: bgImage1,
    },
    {
      heading:
        "Protect Your Future <br /> With Affordable <br /> Term Life Insurance",
      text: "",
      phoneNumber: "(888) 525-2918",
      image: bgImage2,
    },
    {
      heading:
        "Protect Your Future <br /> With Affordable <br /> Term Life Insurance",
      text: "",
      phoneNumber: "(888) 525-2918",
      image: bgImage3,
    },
  ];
  const slides1 = [
    {
      heading:
        "Protect Your Future <br /> With Affordable <br /> Term Life Insurance",
      text: "",
      phoneNumber: "(888) 525-2918",
      image: bgImage3,
    },
  ];
  return (
    <div>
      <Header phoneNumber="(888) 525-2918" />
      <div className="aboslute_top">
        <img src={logo} alt="" />
      </div>
      <div className="absolute_bottom">
        <div className="tp-slide-btn-box">
          <a className="tp-btn-3" href="tel:+18885252918">
            Call Now: (888) 525-2918
          </a>
        </div>
      </div>
      <div className="dess">
        <Slider slides={slides} />
      </div>
      <div className="mbls">
        <Slider2 slides={slides1} />
      </div>

      <div class="tp-about-3__area pt-40 pb-20">
        <div class="container">
          <div class="row g-0">
            <div class="col-xl-5 col-lg-6 col-md-12">
              <div class="tp-about-3__wrapper p-relative mr-20 mt-40 mb-70">
                <div class="tp-about-3__box">
                  <div class="ms-img fg">
                    <img src={img4} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-md-12">
              <div class="wrapper-box">
                <div class="tp-section-box text-start mb-30">
                  {/* <div class="tp-section-subtitle mb-10">
                    Lorem ipsum dolor sit.
                  </div> */}
                  <h2 class="tp-section-title mb-20">
                    Secure Your Family's <br />
                    Financial Stability Today
                  </h2>
                  <p class="tp-section-pre">
                    Life insurance supports you when it matters most. Ensure
                    security for you and your loved ones. We handle the hard
                    work, comparing quotes from top providers to find the
                    perfect policy for your needs and budget.
                  </p>
                </div>
                <div class="tp-about-3__content">
                  <div class="row">
                    <div class="col-xl-8">
                      <div class="tp-about-3__wrap">
                        {/* <div class="help-list">
                          <ul>
                            <li>
                              <i class="fa-solid fa-badge-check"></i>
                              <span>Lorem, ipsum dolor Lorem.</span>
                            </li>
                            <li>
                              <i class="fa-solid fa-badge-check"></i>
                              <span>
                                Lorem ipsum dolor sit amet consectetur.
                              </span>
                            </li>
                            <li>
                              <i class="fa-solid fa-badge-check"></i>
                              <span>Lorem ipsum dolor sit.</span>
                            </li>
                          </ul>
                        </div> */}
                        <div class="tp-about-3__btn-box pt-20">
                          <a class="tp-btn-3" href="tel:+18885252918">
                            Call Now: (888) 525-2918
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tp-feature-3__area p-relative fix pt-40 pb-20 grey-bg-1">
        {/* <div class="tp-feature-3__bg-amg">
          <img src={img6} alt="" />
        </div> */}
        <div class="container">
          <div class="row">
            <div className="centering">
              <h2 class="tp-section-title mb-10">
                We Connect You <br className="mbll" /> to the Best
              </h2>
              <p class="tp-section-pre ">
                We understand the importance of finding the right life insurance
                policy for your specific needs and budget. That's why we partner
                with top-rated providers, offering a wide range of coverage
                options.
              </p>
              <div class=" tp-section-subtitle mb-10">
                Benefits of Life Insurance
              </div>
            </div>
          </div>
          <div class="row roow">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
              <div
                class="tp-features-3 wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="300ms"
              >
                <div class="tp-features-3__item p-relative mb-25">
                  <div class="tp-features-3__img">
                    <img class="w-100" src={img7} alt="" />
                  </div>
                  <div class="tp-features-3__info">
                    <div class="tp-features-3__icon pb-95">
                      {/* <i class="flaticon-house"></i> */}
                      <img src={icon5} alt="" />
                    </div>
                    <div class="tp-features-3__content">
                      <h3 class="tp-features-3__title">Cover funeral costs</h3>
                    </div>
                  </div>
                  <div class="tp-features-3__bg-number">
                    <h2>01</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
              <div
                class="tp-features-3 wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="600ms"
              >
                <div class="tp-features-3__item p-relative mb-25">
                  <div class="tp-features-3__img">
                    <img class="w-100" src={img8} alt="" />
                  </div>
                  <div class="tp-features-3__info">
                    <div class="tp-features-3__icon pb-95">
                      {/* <i class="flaticon-save-money"></i> */}
                      <img src={icon9} alt="" />
                    </div>
                    <div class="tp-features-3__content">
                      <h3 class="tp-features-3__title">Replace lost income</h3>
                    </div>
                  </div>
                  <div class="tp-features-3__bg-number">
                    <h2>02</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
              <div
                class="tp-features-3 wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="900ms"
              >
                <div class="tp-features-3__item p-relative mb-25">
                  <div class="tp-features-3__img">
                    <img class="w-100" src={img9} alt="" />
                  </div>
                  <div class="tp-features-3__info">
                    <div class="tp-features-3__icon pb-95">
                      {/* <i class="flaticon-save-money-1"></i> */}
                      <img src={icon22} alt="" />
                    </div>
                    <div class="tp-features-3__content">
                      <h3 class="tp-features-3__title">Pay off debts</h3>
                    </div>
                  </div>
                  <div class="tp-features-3__bg-number">
                    <h2>03</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
              <div
                class="tp-features-3 wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="900ms"
              >
                <div class="tp-features-3__item p-relative mb-25">
                  <div class="tp-features-3__img">
                    <img class="w-100" src={img9} alt="" />
                  </div>
                  <div class="tp-features-3__info">
                    <div class="tp-features-3__icon pb-95">
                      {/* <i class="flaticon-save-money-1"></i> */}
                      <img src={icon8} alt="" />
                    </div>
                    <div class="tp-features-3__content">
                      <h3 class="tp-features-3__title">Fund education</h3>
                    </div>
                  </div>
                  <div class="tp-features-3__bg-number">
                    <h2>04</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
              <div
                class="tp-features-3 wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="900ms"
              >
                <div class="tp-features-3__item p-relative mb-25">
                  <div class="tp-features-3__img">
                    <img class="w-100" src={img9} alt="" />
                  </div>
                  <div class="tp-features-3__info">
                    <div class="tp-features-3__icon pb-95">
                      {/* <i class="flaticon-save-money-1"></i> */}
                      <img src={icon7} alt="" />
                    </div>
                    <div class="tp-features-3__content">
                      <h3 class="tp-features-3__title">Leave a legacy</h3>
                    </div>
                  </div>
                  <div class="tp-features-3__bg-number">
                    <h2>05</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="tp-slide-btn-box cen">
              <a class="tp-btn-3" href="tel:+18885252918">
                Call Now: (888) 525-2918
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="tpchoose-3__area grey-bg-1 fix pt-40 pb-20 p-relative">
        <div class="tpchoose-3__right-bg">
          <img src="assets/img/choose/choose-right-bg.png" alt="" />
        </div>
        <div class="tpchoose-3__shap p-relative"></div>
        <div class="container ct">
          <div class="row">
            <div class="tpchoose-3__wrap ">
              <div class="tp-section-box text-start mb-30">
                <div class="tp-section-subtitle text-centerr mb-10">
                  WHY YOU CHOOSE US
                </div>
                <h2 class="tp-section-title text-center pb-50">
                  Trust Us For The Rest Of Your Life
                </h2>
                <p class="tp-section-pre"></p>
              </div>

              <div class="row">
                <div class="col-xl-4  col-lg-4 col-md-4 col-sm-4">
                  <div class="tpchoose-3">
                    <div class="tpchoose-3__icon">
                      {/* <i class="flaticon-lowest-price"></i> */}
                      <img src={icon11} alt="" />
                    </div>
                    <div class="tpchoose-3__content pt-20">
                      <h3 class="tpchoose-title">Our Mission</h3>
                      <p>
                        To connect you with the best life insurance providers
                        for your needs and budget, ensuring peace of mind for
                        you and your loved ones.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 mg col-lg-4 col-md-4 col-sm-4">
                  <div class="tpchoose-3">
                    <div class="tpchoose-3__icon">
                      {/* <i class="flaticon-cyber-security"></i> */}
                      <img src={icon13} alt="" />
                    </div>
                    <div class="tpchoose-3__content pt-20">
                      <h3 class="tpchoose-title">
                        Partnering with Top-Rated Providers
                      </h3>
                      <p>
                        We connect you with a network of reputable life
                        insurance companies, offering a wide range of coverage
                        options . Get Quotes Tailored To Your Specific Needs And
                        Budget, All In One Call
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 mg col-lg-4 col-md-4 col-sm-4">
                  <div class="tpchoose-3">
                    <div class="tpchoose-3__icon">
                      {/* <i class="flaticon-administration"></i> */}
                      <img src={icon9} alt="" />
                    </div>
                    <div class="tpchoose-3__content pt-20">
                      <h3 className="tpchoose-3__content">Solution</h3>
                      <h3 class="tpchoose-title">
                        Simplify Your Life Insurance Search
                      </h3>
                      <p>
                        Simplified term life insurance with no medical exams is
                        available from many providers. We can connect you with
                        companies specializing in these plans, increasing your
                        chances of finding coverage regardless of your health
                        history.Secure Your Family's Financial Stability Today
                        Protection Is Only a Call Away Get Your Free Life
                        Insurance Quote!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tp-slide-btn-box ">
              <a class="tp-btn-3" href="tel:+18885252918">
                Call Now: (888) 525-2918
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="tp-cta-3__area theme-bg fix p-relative pt-60 pb-55">
        <div class="tp-cta3-left-bg">
          <img class="w-100" src={img12} alt="" />
        </div>
        <div class="tp-cta-3__shap">
          <img src={img13} alt="" />
        </div>
        <div class="tp-cta-3__shap2">
          <img src={img14} alt="" />
        </div>
        <div class="container pr-0">
          <div class="row align-items-center">
            <div class="offset-xl-2 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-12">
              <div class="tp-cta-3__left">
                <div class="tp-cta-3__icon">
                  <span>
                    {" "}
                    <img className="icn14" src={icon14} alt="" />
                  </span>
                </div>
                <div class="tp-cta-3__info">
                  <h3 id="cta_title" class="cta-titile">
                    Secure Your Family's <br className="mbll" /> Financial
                    Stability Today
                    <br /> Protection is Only a Call Away <br /> Get Your Free
                    Life Insurance Quote!{" "}
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="tp-cta-3__right text-end">
                <div class="tp-cta-3__btn">
                  <a class="tp-btn-3 wht" href="tel:+18885252918">
                    Call Now: (888) 525-2918
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer phoneNumber="(888) 525-2918" />
    </div>
  );
}
