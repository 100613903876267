import React from "react";
import { Link } from "react-router-dom";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
import Header from "../components/Header";
import logo from ".././assets/img/logo/logo.png";
import logo1 from ".././assets/img/logo/logo-black.png";
// Import your images if needed
import Footer from "../components/Footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <Header phoneNumber="(888) 525-2918" />

      <div className="aboslute_top">
        <img src={logo} alt="" />
      </div>
      <div className="absolute_bottom">
        <div className="tp-slide-btn-box">
          <a className="tp-btn-3" href="tel:+18885252918">
            Call Now: (888) 525-2918
          </a>
        </div>
      </div>
      <div className="disclaimer-content">
        <div className="one">
          {" "}
          <div className="tp-section-subtitle hd">Privacy Policy</div>
          {/* <div className="tp-section-subtitle">WEBSITE DISCLAIMER</div> */}
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC operates
            the usavelifeinsurance.com website, which provides the SERVICE.
          </p>
          <p className="tp-section-prr">
            This page informs website visitors regarding our policies regarding
            the collection, use, and disclosure of Personal Information if
            anyone decides to use our Service, on the U Save Life Insurance
            website.
          </p>
          <p className="tp-section-prr">
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that we collect is used for providing and improving the
            Service. We will not use or share your information except as
            described in this Privacy Policy.
          </p>
          <p className="tp-section-prr">
            The terms used in this Privacy Policy have the same meanings as our
            Terms and Conditions, which are accessible
            at usavelifeinsurance.com unless otherwise defined in this Privacy
            Policy.
          </p>
          <div className="tp-section-subtitle hd">Definitions</div>
          <div className="tp-section-subtitle">Service</div>
          <p className="tp-section-prr">
            Service is the Usavelifeinsurance.com operated by Intermedia
            Marketing Group LLC.
          </p>
          <div className="tp-section-subtitle">Personal Data</div>
          <p className="tp-section-prr">
            Personal Data pertains to information about a living person(s) who
            can be identified from this data or other information in our
            possession or likely to come into our possession
          </p>
          <div className="tp-section-subtitle">Usage Data</div>
          <p className="tp-section-prr">
            Usage Data has been collected automatically and is generated by the
            use of the Service, such as the duration of a visit to the site.
          </p>
          <div className="tp-section-subtitle">Cookies</div>
          <p className="tp-section-prr">
            Cookies are files with a small amount of data commonly used as a
            unique anonymous identifier. These are sent to your browser from the
            website you visit and stored on your computer’s hard drive.
          </p>
          <div className="tp-section-subtitle">Data Controller</div>
          <p className="tp-section-prr">
            Data Controller pertains to the legal entity tho controls your
            information while participating in the Service. We are a Data
            Controller of your personal information. We determine the purposes
            for which it will be processed and how it’s done in an effective
            manner that ensures compliance with all relevant laws while still
            maintaining the trust of our users.
          </p>
          <div className="tp-section-subtitle">
            Data Processors (or Service Providers)
          </div>
          <p className="tp-section-prr">
            The Data Processor (or Service Provider) is any natural or legal
            person who processes data on behalf of the Data Controller. We may
            use various service providers to process your information more
            effectively.
          </p>
          <div className="tp-section-subtitle">Data Subject (or User)</div>
          <p className="tp-section-prr">
            Data Subjects are any individuals who use our Service and whose
            Personal Data we collect.
          </p>
          <div className="tp-section-subtitle hd">
            Information Collection and Use
          </div>
          <div className="tp-section-subtitle">Personal Data</div>
          <p className="tp-section-prr">
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, phone number, and postal
            address. The information that we collect will be used to contact or
            identify you.
          </p>
          <p className="tp-section-prr">
            We will only use your Personal Data to contact you with newsletters,
            marketing or promotional materials, and other information that may
            be interesting. You can opt out by following instructions in any
            email we send — however, this means unsubscribing from all emails
            which could contain important updates.
          </p>
          <div className="tp-section-subtitle">Usage Data</div>
          <p className="tp-section-prr">
            We want to inform you that whenever you visit our Service, we
            collect information that your browser sends to us called Usage Data.
            This Usage Data may include information such as your computer’s
            Internet Protocol (“IP”) address, browser version, pages of our
            Service that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
          </p>
          <div className="tp-section-subtitle">Location Data</div>
          <p className="tp-section-prr">
            Location data is used to provide features of our service and improve
            and customize the way in which we offer it. You control when your
            location services are activated or deactivated by using settings on
            any device you use for internet browsing with us — including mobile
            devices.
          </p>
          <div className="tp-section-subtitle">Cookie Data</div>
          <p className="tp-section-prr">
            Our website uses Cookies to collect information and to improve our
            Service. You can either accept or refuse these cookies and know when
            a cookie is being sent to your computer. If you choose to refuse our
            cookies, you may not be able to use some portions of our Service.
          </p>
          <div className="tp-section-subtitle">
            Examples of Cookies we use include:
          </div>
          <ul>
            <li>
              <b>Session Cookies —</b>  allows for Service operation
            </li>
            <li>
              <b>Preference Cookies — </b>allows for remembering your
              preferences and settings
            </li>
            <li>
              <b>Security Cookies —</b>for security purposes
            </li>
          </ul>
          <div className="tp-section-subtitle">Service Providers</div>
          <p className="tp-section-prr">
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p className="tp-section-prr">
            We want to inform our Service users that these third parties have
            access to your Personal Data. The reason is to perform the tasks
            assigned to them on our behalf. However, they are obligated not to
            disclose or use the information for any other purpose.
          </p>
          <div className="tp-section-subtitle">Security</div>
          <p className="tp-section-prr">
            We value your trust in providing us with your Personal Data. Thus,
            we strive to use commercially acceptable means of protecting it. But
            remember that no method of transmission over the internet or method
            of electronic storage is 100% secure and reliable, and we cannot
            guarantee its absolute security.
          </p>
          <div className="tp-section-subtitle">Use of Data</div>
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC collects your browsing data so to
            provide you with various services. The collected information is used
            for several purposes, including:
          </p>
          <ul>
            <li>
              To notify users about changes in service offerings or adjustments
              based on user feedback
            </li>
            <li>
              Maintain an effective customer experience by gathering analysis of
              how people are using the Service’s features when allowed to
              participate via email newsletter subscription process (if
              applicable)
            </li>
            <li>
              Monitoring technical issues affecting performance across their
              network which may lead to providing news updates/special offers
            </li>
          </ul>
        </div>
        <div className="two">
          {" "}
          <div className="tp-section-subtitle">
            Processing Personal Data under the General Data Protection
            Regulation (GDPR)
          </div>
          <p className="tp-section-prr">
            If you are from the European Economic Area (EEA), Intermedia
            Marketing Group LLC may collect and use the Personal Data described
            in this Privacy Policy depending on the specific context in which it
            is collected.
          </p>
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC may process your Personal Data due
            to:
          </p>
          <ul>
            <li>A contract needing to be performed and fulfilled</li>
            <li>You have given us express permission to do so</li>
            <li>
              Processing is in our legitimate interests and does not override
              your rights
            </li>
            <li>Compliance with the law</li>
          </ul>
          <div className="tp-section-subtitle">Retention of Data</div>
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC will retain your Personal Data for as
            long as necessary for the sole purposes set forth within this
            Privacy Policy. We will retain and use your Personal Data only to
            comply with legal obligations, resolve disputes, and enforce our
            legal agreements and policies.
          </p>
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC will also retain Usage Data for
            internal analysis, such as when needed to strengthen the security or
            improve the functionality of our Service. We will also retain this
            data as legally obligated to do so.
          </p>
          <div className="tp-section-subtitle">Transfer of Data</div>
          <p className="tp-section-prr">
            Your information, including your Personal Data, may be transferred
            to and maintained on computers located outside of your state,
            province, country, or other governmental jurisdiction where data
            protection laws may differ from those in your jurisdiction.
          </p>
          <p className="tp-section-prr">
            By providing us with any information you choose to share, including
            Personal Data like name and address or phone number, you give
            Intermedia Marketing Group LLC permission to transfer that data
            across borders within the United States (and other countries), which
            is processed there.
          </p>
          <p className="tp-section-prr">
            You agree this Privacy Policy followed means to accept all
            obligations imposed upon its use at destinations outside the United
            States.
          </p>
          <div className="tp-section-subtitle hd">Disclosure of Data</div>
          <div className="tp-section-subtitle">
            Disclosure for Law Enforcement
          </div>
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC has a responsibility to protect your
            Personal Data. We will never release any of it without complying
            with all applicable laws and responding properly if asked by law
            enforcement or other authorities.
          </p>
          <div className="tp-section-subtitle">Legal Requirements</div>
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC may disclose your Personal Data in
            the good faith belief that such action is necessary to:
          </p>
          <ul>
            <li>
              To notify users about changes in service offerings or adjustments
              based on user feedback
            </li>
            <li>Comply with a legal obligation</li>
            <li>
              Protect and defend the rights or property of Intermedia Marketing
              Group LLC
            </li>
            <li>
              Protect clients & employees from any harm caused by their actions
              where it cannot be avoided through reasonable precautions taken
              beforehand
            </li>
            <li>
              Prevent or investigate potential wrongdoing in connection with the
              Service
            </li>
            <li>Protect the personal safety of users of the Service</li>
            <li>Protect against personal liability</li>
          </ul>
          <div className="tp-section-subtitle">Security of Data</div>
          <p className="tp-section-prr">
            The security of your data is a top priority for us. We’ll do
            everything we can to protect it, but remember that no electronic
            storage media method will ever be 100% secure when sending
            information over the internet.
          </p>
          <div className="tp-section-subtitle">
            Our Policy on “Do Not Track” Signals under the California Online
            Protection Act
          </div>
          <p className="tp-section-prr">
            We do not support Do Not Track (DNT). You can set this preference in
            your web browser to inform websites that they should not track your
            activity.
          </p>
          <p className="tp-section-prr">
            You can enable or disable this feature through the preferences menu
            on any given website by adjusting the appropriate settings on your
            web browser.
          </p>
          <div className="tp-section-subtitle">
            Your Data Protection Rights Under GDPR
          </div>
          <p className="tp-section-prr">
            If you are a resident of the European Economic Area (EEA), then we
            aim to make certain that your Personal Data is processed fairly and
            in accordance with data protection laws.
          </p>
          <p className="tp-section-prr">
            Should you request information about the Personal Data we hold about
            you or want it removed, please contact us so we may act accordingly.
          </p>
          <p className="tp-section-prr">
            You may also have the following data protection rights:
          </p>
          <ul>
            <li>
              <b>The right to be informed — </b>You have the right to be told
              how your Personal Data will be processed by the Data Controller.
              This is normally done through a privacy notice.
            </li>
            <li>
              <b>The right to access your Personal Data — </b>You have the right
              to ask the Data Controller whether they are processing your
              Personal Data, and if they are, you can request a copy of that
              personal information.
            </li>
            <li>
              <b>The right of rectification — </b> You have the right to ensure
              your information is rectified if it is found to be incomplete or
              inaccurate.
            </li>
            <li>
              <b>The right to have your Personal Data erased —</b> Also known as
              the “right to be forgotten” and, in certain circumstances, you can
              ask the Data Controller to erase your data.
            </li>
            <li>
              <b>The right to withdraw consent — </b>You have the right to
              withdraw your consent at any point in time when Intermedia
              Marketing Group LLC relies on your consent to process personal
              information.
            </li>
            <li>
              <b>The right to restrict processing of your Personal Data — </b>
              You have the right to ask the Data Controller to restrict or stop
              processing your Personal Data under certain circumstances.
            </li>
            <li>
              <b>The right to data portability —</b>You have the right, in
              certain circumstances, to ask the Data Controller for a copy of
              your data to re-use for your own purposes.
            </li>
            <li>
              <b>The right to object —</b>You have the right, in certain
              circumstances, to object to the Data Controller processing your
              data.
            </li>
            <li>
              <b>
                Rights in relation to automated decision making and profiling —
              </b>
               You have the right, in certain circumstances, to ask the Data
              Controller not to make decisions about you that are based solely
              on automated processing or profiling
            </li>
          </ul>
          <p className="tp-section-prr">
            Please note that to remain compliant with law enforcement, we may
            ask that you verify your identity before responding to or taking
            action on your request.
          </p>
          <p className="tp-section-prr">
            You also have the right to file a complaint with a Data Protection
            Authority about our collection and use of your information. If you
            have questions or want more information, please contact your local
            European Economic Area (EEA) data protection authority.
          </p>
        </div>
        <div className="three">
          <div className="tp-section-subtitle">Service Providers</div>
          <p className="tp-section-prr">
            We may employ third-party companies (Service Providers) to help us
            deliver our Service, such as by handling customer support or sending
            email newsletters about new services. These Service Providers have
            access to Personal Data but are prohibited from disclosing any
            information you shared with them unless necessary.
          </p>
          <p className="tp-section-prr">
            Service Providers are only to perform tasks on our behalf and may
            not use or disclose your Personal Data for any other purpose.
          </p>

          <div className="tp-section-subtitle">Analytics</div>
          <p className="tp-section-prr">
            We may use third-party Service Providers to complete an analysis of
            the Service’s use and monitor user activity.
          </p>

          <div className="tp-section-subtitle">Google Analytics</div>
          <p className="tp-section-prr">
            Google uses the data collected from its Analytics service to tailor
            a more personalized user experience. The information gathered by
            Google Analytics doesn’t contain any personally identifiable data,
            like your name or email address. It simply records how many visitors
            there were on a specific day and time frame.
          </p>
          <p className="tp-section-prr">
            You can choose to opt out of making your activity on the Service
            available to Google Analytics by installing the appropriate browser
            add-on. The Google Analytics opt-out browser add-on prevents sharing
            information with Google Analytics about your activity.
          </p>
          <p className="tp-section-prr">
            For additional information on Google’s privacy practices, visit the
            Google Privacy Terms web page found here.
          </p>

          <div className="tp-section-subtitle">Behavioral Remarketing</div>
          <p className="tp-section-prr">
            Intermedia Marketing Group LLC uses remarketing services to
            advertise on other third-party websites you visit after visiting the
            Service. Cookies are used to inform, optimize, and serve
            advertisements based on prior visits to the Service.
          </p>

          <div className="tp-section-subtitle">Google AdWords</div>
          <p className="tp-section-prr">
            Google AdWords is a service provided by Google Inc., which allows
            Intermedia Marketing Group LLC to advertise the Service through
            online advertisements. You can opt-out of this advertising
            program to not have data collected and used for personalized content
            based on visitors’ browsing behavior when using web browsers such as
            Chrome and Firefox.
          </p>

          <div className="tp-section-subtitle">Bing Ads Remarketing</div>
          <p className="tp-section-prr">
            Bing Ads Remarketing is provided by Microsoft Inc. You can also
            choose to opt-out of Bing Ads by following their instructions
            located here.
          </p>
          <p className="tp-section-prr">
            Learn about Microsoft privacy policies by visiting their Privacy
            Policy page.
          </p>
          <div className="tp-section-subtitle">Links to Other Sites</div>
          <p className="tp-section-prr">
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for any third-party sites
            or services’ content, privacy policies, or practices.
          </p>

          <div className="tp-section-subtitle">Children's Privacy</div>
          <p className="tp-section-prr">
            Our Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. If we discover that a child under 13 has provided us with
            personal information, we immediately delete this from our servers.
            If you are a parent or guardian and you are aware that your child
            has provided us with personal information, please contact us so that
            we will be able to take the necessary actions.
          </p>

          <div className="tp-section-subtitle">
            Changes to This Privacy Policy
          </div>
          <p className="tp-section-prr">
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately after they are posted on
            this page.
          </p>
        </div>
      </div>

      <Footer phoneNumber="(888) 525-2918" />
    </div>
  );
}
