import React from "react";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";

const Slider2 = ({ slides }) => {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  return (
    <div className="tp-slider__area p-relative">
      {slides.map((slide, index) => {
        const telPhoneNumber = `+1${formatPhoneNumberForTel(
          slide.phoneNumber
        )}`;
        return (
          <div className="swiper-slide mbole" key={index}>
            <div className="slider-item single-slider tp-slider-overlay tp-slider__height d-flex align-items-center p-relative z-index-1">
              <div
                className="tp-slider__bg"
                style={{ backgroundImage: `url(${slide.image})` }}
              ></div>
              <div className="tp-slider-3__bg-3"></div>
              <div className="tp-slider-3__shap-left z-index-5"></div>
              <div className="tp-slider-3__shap news z-index-2"></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="tp-slider-3__content p-relative text-center z-index-1">
                      <h2
                        className="tp-slider-title mb-50"
                        dangerouslySetInnerHTML={{ __html: slide.heading }}
                      ></h2>
                      <p className="tp-slider-pragrap">{slide.text}</p>
                      <div className="tp-slide-btn-box">
                        <a className="tp-btn-3" href={`tel:${telPhoneNumber}`}>
                          Call Now: {slide.phoneNumber}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Slider2;
