import React from "react";
import logo1 from ".././assets/img/logo/logo-black.png";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";

const Header = ({ phoneNumber }) => {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(phoneNumber)}`;

  return (
    <header>
      <div className="tpheader-3__top pt-10 pb-10 grey-bg-1 d-none d-md-block">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-lg-3 col-md-3 col-sm-4 col-8">
              <div className="tpheader-3__wrapper">
                <div className="tpheader-3__logo">
                  <a href="">
                    <img className="lg" src={logo1} alt="Logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-3 new col-md-3 col-sm-2 col-1">
              <div className="tpheader-3__contact-number">
                <div className="tpheader-3__smcontact-list ml-20">
                  <div className="sm-clist__text text-start ml-15 mr-25">
                    <a className="tp-btn-3" href={`tel:${telPhoneNumber}`}>
                      Call Now: {phoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="header-sticky" className="tpheader-3__bottom header__sticky">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-9 col-lg-8">
              <div className="main-menu text-left">
                <div className="tpheader-3__wrapper-inner">
                  <div className="tpheader-3__menu text-center">
                    <div className="mean-push"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
