import React, { useEffect } from "react";

export default function ExternalScriptsLoader() {
  useEffect(() => {
    function loadRingba() {
      const script = document.createElement("script");
      script.id = "__script__id__";
      script.type = "text/javascript";
      script.async = true;
      script.src = "//b-js.ringba.com/CA32f1c66d04ee4bafa5491b91cbba6cdf";
      document.body.appendChild(script);
      return script;
    }

    function loadScript() {
      console.log("Universal script loaded");
      const script = document.createElement("script");
      script.id = "__universal_script__";
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://track.imgtrx3.com/uniclick.js?attribution=lastpaid&cookiedomain=usavelifeinsurance.com&cookieduration=30&defaultcampaignid=632a57e371a1c80001b06e02&regviewonce=false";
      document.body.appendChild(script);
      return script;
    }

    function checkCookie() {
      console.log("Checking Cookie");
      const myInterval = setInterval(() => {
        const myCookie = sessionStorage.getItem("rtkclickid");
        if (myCookie && window._rgba_tags) {
          console.log("COOKIE RECEIVED", myCookie);
          (window._rgba_tags = window._rgba_tags || []).push({
            clickID: myCookie,
          });
          clearInterval(myInterval);
        } else {
          console.log("Cookie is not there");
        }
      }, 2000);
    }

    // Load the scripts
    const ringbaScript = loadRingba();
    const universalScript = loadScript();

    // Start checking cookies
    checkCookie();

    // Cleanup function
    return () => {
      if (ringbaScript) document.body.removeChild(ringbaScript);
      if (universalScript) document.body.removeChild(universalScript);
    };
  }, []);

  return null;
}
