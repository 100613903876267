import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";

const Slider = ({ slides }) => {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  return (
    <div className="tp-slider__area p-relative">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".tpslick-prev",
          prevEl: ".tpslick-next",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="swiper_container"
      >
        {slides.map((slide, index) => {
          const telPhoneNumber = `+1${formatPhoneNumberForTel(
            slide.phoneNumber
          )}`;
          return (
            <SwiperSlide key={index}>
              <div className="swiper-slide">
                <div className="slider-item single-slider tp-slider-overlay tp-slider__height d-flex align-items-center p-relative z-index-1">
                  <div
                    className="tp-slider__bg"
                    style={{ backgroundImage: `url(${slide.image})` }}
                  ></div>
                  <div className="tp-slider-3__bg-3"></div>
                  <div className="tp-slider-3__shap-left z-index-5"></div>
                  <div className="tp-slider-3__shap news z-index-2"></div>
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="tp-slider-3__content p-relative text-center z-index-1">
                          <h2
                            className="tp-slider-title mb-50"
                            dangerouslySetInnerHTML={{ __html: slide.heading }}
                          ></h2>
                          <p className="tp-slider-pragrap">{slide.text}</p>
                          <div className="tp-slide-btn-box">
                            <a
                              className="tp-btn-3"
                              href={`tel:${telPhoneNumber}`}
                            >
                              Call Now: {slide.phoneNumber}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="tp-slider-3__nav slider-arrow-style">
        <button className="tpslick-prev">
          <i className="fa-regular fa-arrow-right"></i>
        </button>
        <button className="tpslick-next">
          <i className="fa-regular fa-arrow-left"></i>
        </button>
      </div>
    </div>
  );
};

export default Slider;
