import React from "react";
import { Link } from "react-router-dom";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
import Header from "../components/Header";
import logo from ".././assets/img/logo/logo.png";
import logo1 from ".././assets/img/logo/logo-black.png";
// Import your images if needed
import Footer from "../components/Footer";

export default function Disclaimer() {
  return (
    <div>
      <Header phoneNumber="(888) 525-2918" />

      <div className="aboslute_top">
        <img src={logo} alt="" />
      </div>
      <div className="absolute_bottom">
        <div className="tp-slide-btn-box">
          <a className="tp-btn-3" href="tel:+18885252918">
            Call Now: (888) 525-2918
          </a>
        </div>
      </div>
      <div className="disclaimer-content">
        <div className="one">
          <div className="tp-section-subtitle hd">DISCLAIMER</div>
          <div className="tp-section-subtitle">WEBSITE DISCLAIMER</div>
          <p className="tp-section-prr">
            The information provided by U Save Life Insurance ("we," "us," or
            "our") on usavelifeinsurance.com (the "Site") is for general
            informational purposes only. All information on the Site is provided
            in good faith, however we make no representation or warranty of any
            kind, express or implied, regarding the accuracy, adequacy,
            validity, reliability, availability, or completeness of any
            information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
            LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
            RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION
            PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY
            INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
          </p>
        </div>
        <div className="two">
          <div className="tp-section-subtitle">PROFESSIONAL DISCLAIMER</div>
          <p className="tp-section-prr">
            The Site cannot and does not contain life insurance advice. The life
            insurance information is provided for general informational and
            educational purposes only and is not a substitute for professional
            advice. Accordingly, before taking any actions based upon such
            information, we encourage you to consult with the appropriate
            professionals. We do not provide any kind of life
            insurance advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED
            ON THE SITE IS SOLELY AT YOUR OWN RISK.
          </p>
        </div>
        <div className="three">
          {" "}
          <div className="tp-section-subtitle">TCPA DISCLAIMER</div>
          <p className="tp-section-prr">
            Notwithstanding any current or prior election to opt in or opt out
            of receiving telemarketing calls, or SMS messages (text messages)
            from our representatives, agents, affiliates, you expressly consent
            to be contacted by U SAVE LIFE INSURANCE, our agents,
            representatives, affiliates, or anyone calling on our behalf. This
            is for any purposes relating to your request for life insurance
            service, at any telephone number, or physical or electronic address
            you provide or at which you may be reached. You agree we may contact
            you in any way, including SMS messages (text messages), calls using
            prerecorded messages or artificial voice, and calls and messages
            delivered using auto telephone dialing system or an automatic
            texting system. Automated messages may be played when the telephone
            is answered, whether by you or someone else. In the event that an
            agent or representative of U SAVE LIFE INSURANCE calls, he or she
            may also leave a message on your answering machine, voice mail, or
            send one via text. You certify that the telephone numbers that you
            have provided, are in fact your contact numbers, and you agree to
            receive calls at each phone number you have provided U SAVE LIFE
            INSURANCE. You agree to promptly alert U SAVE LIFE INSURANCE in the
            event that you stop using a particular phone number. Your
            cell/mobile telephone provider will charge you according the type of
            account you carry with those companies. You may opt out of any
            future contact via text message by replying anytime with “STOP”. You
            agree that U SAVE LIFE INSURANCE may contact you by email, using any
            email address you have provided or that you provide in the future.
            You may opt out of any future contact via email message by replying
            anytime with “UNSUBSCRIBE”. U SAVE LIFE INSURANCE may listen to
            and/or record calls between you and any representative without
            notice, as permitted by applicable laws. For example we listen to
            calls to monitor for quality purposes.
          </p>
        </div>
      </div>

      <Footer phoneNumber="(888) 525-2918" />
    </div>
  );
}
