// src/components/ChooseUsSection.jsx
import React from "react";
import i1 from "../assets/img/simple logo/simple logo/11.png";
import i2 from "../assets/img/simple logo/simple logo/13.png";
import i3 from "../assets/img/simple logo/simple logo/9.png";
import i4 from "../assets/img/simple logo/simple logo/fund.png";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
const ChooseUsSection = ({ phoneNumber }) => {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(phoneNumber)}`;
  return (
    <div className="tpchoose-3__area grey-bg-1 fix pt-40 pb-20 p-relative">
      <div className="tpchoose-3__right-bg">
        <img src="assets/img/choose/choose-right-bg.png" alt="" />
      </div>
      <div className="tpchoose-3__shap p-relative"></div>
      <div className="container">
        <div className="row">
          <div className="tpchoose-3__wrap">
            <div className="tp-section-box text-start mb-30">
              <div className="tp-section-subtitle text-center mb-10">
                WHY YOU CHOOSE US
              </div>
              <h2 className="tp-section-title text-center mb-10">
                Trust Us For The Rest Of Your Life
              </h2>
              <p className="tp-section-pre"></p>
            </div>
            <div className="tpchoose-3__choose-box mt-80">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4">
                  <div className="tpchoose-3">
                    <div className="tpchoose-3__icon">
                      <img src={i1} alt="" />
                    </div>
                    <div className="tpchoose-3__content pt-20">
                      <h3 className="tpchoose-title"></h3>
                      <h3 className="tpchoose-3__content">Our Mission</h3>
                      <p>
                        We Connect You With The Best Term Life Insurance
                        Solutions for a Secure Future That Fit Your Budget
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 mg col-lg-4 col-md-4 col-sm-4">
                  <div className="tpchoose-3">
                    <div className="tpchoose-3__icon">
                      <img src={i2} alt="" />
                    </div>
                    <div className="tpchoose-3__content pt-20">
                      <h3 className="tpchoose-3__content">Offer</h3>
                      <h3 className="tpchoose-title">
                        Reliable, Trusted, and Secure Insurance
                      </h3>
                      <p>
                        Your search for the best life Insurance Ends Here. U
                        Save Life Insurance makes it easy to get personalized
                        quotes from top-rated carriers
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 mg col-lg-4 col-md-4 col-sm-4">
                  <div className="tpchoose-3">
                    <div className="tpchoose-3__icon">
                      <img src={i3} alt="" />
                    </div>
                    <div className="tpchoose-3__content pt-20">
                      <h3 className="tpchoose-3__content">Our Promise</h3>
                      <h3 className="tpchoose-title">
                        Nationwide Leading Life Insurance Companies
                      </h3>
                      <p>
                        Our network of life insurance companies are the top
                        AMBEST rated providers ensuring your families finances
                        are protected.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 mg col-lg-4 col-md-4 col-sm-4">
                  <div className="tpchoose-3">
                    <div className="tpchoose-3__icon">
                      <img src={i4} alt="" />
                    </div>
                    <div className="tpchoose-3__content pt-20">
                      <h3 className="tpchoose-3__content">Guarantee</h3>
                      <h3 className="tpchoose-title">
                        Knowledgeable, Welcoming Guidance
                      </h3>
                      <p>
                        Our specialists are dedicated to making life insurance
                        straightforward and stress-free.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tp-slide-btn-box">
              <a className="tp-btn-3" href={`tel:${telPhoneNumber}`}>
                Call Now: {phoneNumber}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUsSection;
