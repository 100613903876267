import React, { useEffect } from "react";

const ExternalScriptsLoader = () => {
  useEffect(() => {
    // Load Ringba script
    const ringbaScript = document.createElement("script");
    ringbaScript.id = "__ringba_script__";
    ringbaScript.type = "text/javascript";
    ringbaScript.async = true;
    ringbaScript.src = "//b-js.ringba.com/CA49bc0de5df0043da861bedc63a8783ff";
    document.body.appendChild(ringbaScript);

    // Load universal script
    const universalScript = document.createElement("script");
    universalScript.id = "__universal_script__";
    universalScript.type = "text/javascript";
    universalScript.async = true;
    universalScript.src =
      "https://track.imgtrx3.com/uniclick.js?attribution=lastpaid&cookiedomain=usavelifeinsurance.com&cookieduration=30&defaultcampaignid=632a57e371a1c80001b06e02&regviewonce=false";
    document.body.appendChild(universalScript);

    // Check cookies function
    const checkCookie = () => {
      console.log("Checking Cookie");
      const myInterval = setInterval(() => {
        const myCookie = sessionStorage.getItem("rtkclickid");
        if (myCookie && window._rgba_tags) {
          console.log("COOKIE RECEIVED", myCookie);
          (window._rgba_tags = window._rgba_tags || []).push({
            clickID: myCookie,
          });
          clearInterval(myInterval);
        } else {
          console.log("Cookie is not there");
        }
      }, 2000);
    };

    // Start checking cookies
    checkCookie();

    // Cleanup function
    return () => {
      if (ringbaScript) document.body.removeChild(ringbaScript);
      if (universalScript) document.body.removeChild(universalScript);
    };
  }, []);

  return null;
};

export default ExternalScriptsLoader;
