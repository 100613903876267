import React from "react";
import { Link } from "react-router-dom";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
import Header from "../components/Header";
import logo from ".././assets/img/logo/logo.png";
import logo1 from ".././assets/img/logo/logo-black.png";
import Footer from "../components/Footer";
// Import your images if needed

export default function TermsCondition() {
  return (
    <div>
      <Header phoneNumber="(888) 525-2918" />

      <div className="aboslute_top">
        <img src={logo} alt="" />
      </div>
      <div className="absolute_bottom">
        <div className="tp-slide-btn-box">
          <a className="tp-btn-3" href="tel:+18885252918">
            Call Now: (888) 525-2918
          </a>
        </div>
      </div>
      <div className="disclaimer-content">
        <div className="one">
          {" "}
          <div className="tp-section-subtitle hd">Terms and Conditions</div>
          {/* <div className="tp-section-subtitle">WEBSITE DISCLAIMER</div> */}
          <p className="tp-section-prr">
            These Terms and Conditions constitute a legally binding agreement
            made between you, whether personally or on behalf of an entity
            (“you”) and U Save Life Insurance (“we,” “us,” or “our”), concerning
            your access to and use of the usavelifeinsurance.com website as well
            as any other media form, media channel, mobile website or mobile
            application related, linked, or otherwise connected to it
            (collectively, the “Site”).
          </p>
          <p className="tp-section-prr">
            You agree that by accessing the Site, you have read, understood, and
            agree to be bound by all of these Terms and Conditions. If you do
            not agree with all of these Terms and Conditions, you are expressly
            prohibited from using the Site and must discontinue use immediately.
          </p>
          <div className="tp-section-subtitle">Intellectual Property</div>
          <p className="tp-section-prr">
            The Site may contain (or you may be sent via the Site) links to
            other websites (“Third-Party Websites”) as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties (“Third-Party
            Content”).
          </p>
          <p className="tp-section-prr">
            Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us. We are not responsible for any Third-Party
            Websites accessed through the Site or any Third-Party Content posted
            on, available through, or installed from the Site, including the
            content, accuracy, offensiveness, opinions, reliability, privacy
            practices, or other policies of or contained in the Third-Party
            Websites or the Third-Party Content.
          </p>
          <p className="tp-section-prr">
            Inclusion of, linking to, or permitting the use or installation of
            any Third-Party Websites or any Third-Party Content does not imply
            approval or endorsement thereof. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms and Conditions no longer govern.
          </p>
          <p className="tp-section-prr">
            We strongly advise you to read the Third-Party Website terms and
            conditions and the privacy policies of any site or services you
            visit or use.
          </p>
        </div>
        <div className="two">
          <div className="tp-section-subtitle">Indemnification</div>
          <p className="tp-section-prr">
            You agree to defend, indemnify, and hold U Save Life Insurance
            harmless, including our subsidiaries, affiliates, and all of our
            respective officers, agents, partners, and employees, from and
            against any loss, damage, liability, claim, or demand, including
            reasonable attorneys’ fees and expenses, made by any third party due
            to or arising out of:
          </p>
          <ul>
            <li>use of the Site</li>
            <li>breach of these Terms and Conditions</li>
            <li>
              any breach of your representations and warranties outlined in
              these Terms and Conditions
            </li>
            <li>
              your violation of the rights of a third party, including but not
              limited to intellectual property rights
            </li>
            <li>
              any overt harmful act toward any other user of the Site with whom
              you connected via the Site
            </li>
          </ul>
          <div className="tp-section-subtitle">Limitation of Liability</div>
          <p className="tp-section-prr">
            {" "}
            In no event will we or our directors, employees, or agents be liable
            to you or any third party for any direct, indirect, consequential,
            exemplary, incidental, special, or punitive damages, including lost
            profit, lost revenue, loss of data, or other damages arising from
            your use of the site, even if we have been advised of the
            possibility of such damages.
          </p>
          <p className="tp-section-prr">
            Notwithstanding anything to the contrary contained herein, our
            liability to you for any cause whatsoever and regardless of the form
            of the action will at all times be limited to the lesser of the
            amount paid, if any, by you to us during the ONE (1) month period
            before any cause of action arising or US$100. State laws may not
            allow limitations on implied warranties or the exclusion or
            limitation of certain damages.{" "}
          </p>
          <p className="tp-section-prr">
            {" "}
            If certain State laws apply to you, some or all disclaimers found
            within these terms and conditions or limitations may not apply to
            you, and you may have additional rights.
          </p>
        </div>

        <div className="three">
          <div className="tp-section-subtitle">Exclusions</div>
          <p className="tp-section-prr">
            While the limitations above may not apply to you, some countries do
            not allow exclusions or limitations of liability for consequential
            damages, so please check with your jurisdiction before purchasing
            this insurance.{" "}
          </p>
          <div className="tp-section-subtitle">Governing Law</div>
          <p className="tp-section-prr">
            These Terms and Conditions and your use of the Site are governed by
            and construed following the laws of the State of Florida applicable
            to agreements made and to be entirely performed within the State of
            Florida, without regard to its conflict of law principles.{" "}
          </p>
          <div className="tp-section-subtitle">Changes</div>
          <p className="tp-section-prr">
            Supplemental terms and conditions or documents posted on the Site
            from time to time are hereby expressly incorporated herein by
            reference. In our sole discretion, we reserve the right to make
            changes or modifications to these Terms and Conditions at any time
            and for any reason.{" "}
          </p>
          <p className="tp-section-prr">
            We will alert you about any changes by updating the “Last Updated”
            date of these Terms and Conditions, and you waive any right to
            receive specific notice of each such change.{" "}
          </p>
          <p className="tp-section-prr">
            You must periodically review these Terms and Conditions to stay
            informed of updates. You will be subject to and will be deemed to
            have been made aware of and accepted the changes in any revised
            Terms and Conditions by your continued use of the Site after the
            date such revised Terms and Conditions are posted.{" "}
          </p>
        </div>
      </div>
      <Footer phoneNumber="(888) 525-2918" />
    </div>
  );
}
