// src/Components/Footer.jsx
import React from "react";
import { Link } from "react-router-dom";
import ".././assets/css/bootstrap.css";
import ".././assets/css/meanmenu.css";
import ".././assets/css/animate.css";
import ".././assets/css/swiper-bundle.css";
import ".././assets/css/slick.css";
import ".././assets/css/nouislider.css";
import ".././assets/css/magnific-popup.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
import logo from ".././assets/img/logo/logo.png";

const Footer = ({ phoneNumber }) => {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(phoneNumber)}`;
  return (
    <>
      {" "}
     
      <div className="Footer">
        <div className="Footer1">
          {" "}
          <div className="f-logo">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="f-pages">
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>
          <div className="f-contact">
            <h4>Contact</h4>
            <ul>
              <li>
                <a href="mailto: info@usavelifeinsurance.com">
                  info@usavelifeinsurance.com
                </a>{" "}
              </li>
              <li>
                <a href={`tel:${telPhoneNumber}`}> {phoneNumber}</a>{" "}
              </li>
            </ul>
          </div>{" "}
        </div>
        <span>© Copyright 2024 USaveLifeInsurance | All Rights Reserved.</span>
      </div>
    </>
  );
};

export default Footer;
