import React from "react";

import ".././assets/css/bootstrap.css";
import ".././assets/css/font-awesome-pro.css";
import ".././assets/css/flaticon.css";
import ".././assets/css/spacing.css";
import ".././assets/css/main.css";
import img12 from ".././assets/img/cta/cta3-img-right.png";
import img13 from ".././assets/img/cta/shap2.png";
import img14 from ".././assets/img/cta/shap3.png";
import logo from ".././assets/img/logo/logo.png";

// Import your images if needed
import bgImage1 from "../assets/img/lps/sxxx.webp";
import bgImage2 from "../assets/img/lps/family-moving-new-home.webp";
import bgImage3 from "../assets/img/lps/family-with-little-daughter-together-autumnal-weather-having-fun.webp";

import ExternalScriptLoader from "../Scripts/Home_dl_lps";
import icon14 from "../assets/img/simple logo/simple logo/14.png";
import Header from "../components/Header";
import useLoadScript from "../Utility/useLoadScript";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import Slider2 from "../components/Slider2";
import ChooseUsSection from "../components/ChooseUsSection";
export default function Dl_lp2() {
  useLoadScript("../Scripts/Home_dl_lps.js");
  const slides = [
    {
      heading:
        "Ensure Your Loved <br /> One’s Financial Security <br /> with <br /> Term Life Insurance",
      text: "Free Quote",
      phoneNumber: " (877) 382 2797",
      image: bgImage1,
    },
    {
      heading:
        "Ensure Your Loved <br /> One’s Financial Security <br /> with <br /> Term Life Insurance",
      text: "Free Quote",
      phoneNumber: " (877) 382 2797",
      image: bgImage2,
    },
    {
      heading:
        "Ensure Your Loved <br /> One’s Financial Security <br /> with <br /> Term Life Insurance",
      text: "Free Quote",
      phoneNumber: " (877) 382 2797",
      image: bgImage3,
    },
  ];
  const slides1 = [
    {
      heading:
        "Ensure Your Loved One’s Financial Security with <br /> Term Life Insurance",
      text: "Free Quote",
      phoneNumber: " (877) 382 2797",
      image: bgImage2,
    },
  ];
  return (
    <div>
      <ExternalScriptLoader />

      <Header phoneNumber="  (877) 382 2797" />
      <div className="aboslute_top">
        <img src={logo} alt="" />
      </div>
      <div className="absolute_bottom">
        <div className="tp-slide-btn-box">
          <a className="tp-btn-3" href="tel: +18773822797">
            Call Now: (877) 382 2797
          </a>
        </div>
      </div>
      <div className="dess">
        <Slider slides={slides} />
      </div>
      <div className="mbls">
        <Slider2 slides={slides1} />
      </div>

      <ChooseUsSection phoneNumber={" (877) 382 2797"} />
      <div class="tp-cta-3__area theme-bg fix p-relative pt-60 pb-55">
        <div class="tp-cta3-left-bg">
          <img class="w-100" src={img12} alt="" />
        </div>
        <div class="tp-cta-3__shap">
          <img src={img13} alt="" />
        </div>
        <div class="tp-cta-3__shap2">
          <img src={img14} alt="" />
        </div>
        <div class="container pr-0">
          <div class="row align-items-center">
            <div class="offset-xl-2 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-12">
              <div class="tp-cta-3__left">
                <div class="tp-cta-3__icon">
                  <span>
                    {" "}
                    {/* <i class="flaticon-claim"></i> */}
                    <img className="icn14" src={icon14} alt="" />
                  </span>
                </div>
                <div class="tp-cta-3__info">
                  <h3 id="cta_title" class="cta-titile">
                    Plan for a Secure Future with Us
                    <br />
                    Get your Free Quote Today!
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div class="tp-cta-3__right text-end">
                <div class="tp-cta-3__btn">
                  <a class="tp-btn-3 wht" href="tel: +18773822797">
                    Call Now: (877) 382 2797
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer phoneNumber=" (877) 382 2797" />
    </div>
  );
}
